import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PromiseState } from '@react-redux-fetch/core';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { IsbnApplication, IsbnDTO, Publisher, TitleFull } from '../../../../config/api/types';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import security from '../../../security';
import { mapFormValues, mapTitleDTO } from '../../../title/admin/title/dataMapper';
import { FormValues } from '../../../title/admin/title/domain';
import IsbnTitleForm from '../../components/IsbnTitleForm';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: 'flex',
  },
}));

type Props = {
  isbnApplication: IsbnApplication;
  onUpdate: (isbnApplication: IsbnApplication) => void;
  updateRequest: PromiseState | undefined;
};

const EditIsbnTitleWrapper = ({ isbnApplication, onUpdate, updateRequest }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { createSubmissionPromise } = useAsyncValidation(updateRequest);
  const isAuthenticated = useSelector(security.selectors.isAuthenticated);

  const actionButtons = (
    <Button type="submit" fullWidth>
      {t('form_action_save')}
    </Button>
  );

  const handleSubmit = (values: FormValues) => {
    const dto = mapFormValues(values).toTitleDTO();

    onUpdate({
      ...isbnApplication,
      title: {
        ...isbnApplication.title,
        ...dto,
      } as IsbnApplication['title'],
    });

    return createSubmissionPromise().then((result) => {
      if (result) {
        const resultDto = result as Partial<IsbnDTO>;
        return { ...resultDto.title };
      }
      return result;
    });
  };

  const getInitialValues = () => {
    const result = mapTitleDTO(isbnApplication.title as TitleFull).toFormValues();

    const organisation = isbnApplication._embedded?.publisherOrganisation;
    if (organisation) {
      result.publisher = { organisation } as Publisher;
    }

    return result;
  };

  return (
    <Form
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      render={({ values, submitErrors, form: { change }, handleSubmit, errors, touched }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.formContainer}>
                  <IsbnTitleForm
                    values={values}
                    submitErrors={submitErrors}
                    change={change}
                    buttonsLeft={actionButtons}
                    isAuthenticated={isAuthenticated}
                    validationErrors={errors}
                    touched={touched}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default EditIsbnTitleWrapper;
