import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import PrefixForm from '../components/PrefixForm';
import { PrefixSteppedFormProps } from '../types';

const PrefixApplicationPrefixStep = ({
  submitting,
  user,
  values,
  changeStep,
}: PrefixSteppedFormProps) => {
  const { t } = useTranslation();
  const disabled = submitting || (user && !values?.requestIsbnNumbers);

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} md={7}>
        <PrefixForm user={user} values={values} />
      </Grid>

      <Grid container item xs={7} justifyContent="space-between">
        <Grid item>
          <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
            {t('form_previous')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" disabled={disabled}>
            {t('form_next')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrefixApplicationPrefixStep;
