import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import NewPrefixForm from '../components/NewIsbnPrefixForm';
import PrefixFormAuthenticated from '../components/PrefixFormAuthenticated';
import { IsbnSteppedFormProps } from '../types';

const PrefixStep = ({ submitting, user, values, changeStep }: IsbnSteppedFormProps) => {
  const { t } = useTranslation();

  const isValidAnonymousSubmission = !user && Boolean(values.prefix || values.requestIsbnNumbers);
  const isValidAuthenticatedSubmission = user && Boolean(values?.prefix);

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} md={7}>
        {user && values.prefix ? (
          <PrefixFormAuthenticated user={user} values={values} />
        ) : (
          <NewPrefixForm user={user} values={values} />
        )}
      </Grid>

      <Grid container item xs={7} justifyContent="space-between">
        <Grid item>
          <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
            {t('form_previous')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            disabled={submitting || !(isValidAuthenticatedSubmission || isValidAnonymousSubmission)}
          >
            {t('form_next')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrefixStep;
