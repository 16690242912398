import { Grid } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import PageTitle from '../../../components/PageTitle';
import Stepper from '../../../components/Stepper';
import { IsbnDTO } from '../../../config/api/types';
import useApiRoute from '../../../config/api/useApiRoute';
import { useAsyncValidation } from '../../../helpers/finalFormAsyncValidation';
import { getLink } from '../../../helpers/hateoas';
import { getUser } from '../../security/selectors';
import { getPublisherRequest } from '../../title/admin/title/api';
import { propertyPathMapper as defaultPropertyPathMapper } from '../../title/admin/title/containers/TitleForm';
import { createIsbnRequest } from '../api';
import OverviewStep from '../containers/IsbnOverviewStep';
import PrefixStep from '../containers/IsbnPrefixStep';
import PublisherStep from '../containers/IsbnPublisherStep';
import TitleStep from '../containers/IsbnTitleStep';
import { IsbnFormToDto } from '../dataMapper';
import { defaultIsbnFormValues } from '../domain';
import { IsbnFormStep, IsbnFormValues } from '../types';

const propertyPathMapper = Object.keys(defaultPropertyPathMapper).reduce(
  (previousValue: Record<string, string>, property: string) => {
    const prop = property as keyof typeof defaultPropertyPathMapper;
    return {
      ...previousValue,
      [`title.${prop}`]: `title.${defaultPropertyPathMapper[prop]}`,
    };
  },
  {}
);

const ISBNApplicationPage = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [submitIsbnRequest, submitIsbn] = useFetch(createIsbnRequest);
  const { createSubmissionPromise } = useAsyncValidation(submitIsbnRequest, propertyPathMapper);
  const apiRoute = useApiRoute('isbnApplicationCreate') || '';
  const urlParams = useParams<{ isbn: string }>();
  const user = useSelector(getUser);
  const [, fetchPublisher] = useFetch(getPublisherRequest);
  const [formValues, setFormValues] = useState<IsbnFormValues | null>(null);
  const organisation = user?._embedded.organisation;

  const steps: IsbnFormStep[] = [
    {
      name: t('form_isbn_step_publisher'),
      component: PublisherStep,
      fields: ['organisation', 'login'],
    },
    {
      name: t('form_isbn_step_prefix'),
      component: PrefixStep,
      fields: ['prefix', 'requestIsbnNumbers'],
    },
    {
      name: t('form_isbn_step_title'),
      component: TitleStep,
      fields: ['title'],
    },
    {
      name: t('form_isbn_step_overview'),
      component: OverviewStep,
      fields: ['login', 'organisation', 'prefix', 'requestIsbnNumbers', 'title'],
    },
  ];

  useEffect(() => {
    if (organisation) {
      const publisherLink = getLink(organisation, 'publisher') || '';
      fetchPublisher(publisherLink);
    }
  }, [organisation, fetchPublisher]);

  const currentStep = steps[activeStep];
  const Component = currentStep.component;

  const getDataToSubmit = (dto: IsbnDTO) => {
    if (currentStep.fields?.length) {
      const partialDto = {} as IsbnDTO;
      currentStep.fields.forEach((field) => {
        partialDto[field] = dto[field] || (null as any);
      });
      return partialDto;
    }
    return dto;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleIsbnSubmit = (values: IsbnFormValues) => {
    const updatedValues = {
      ...values,
      organisation: {
        ...values.organisation,
        email: values.organisation.email || values.login.email,
      },
    };
    setFormValues(updatedValues);
    const dto = IsbnFormToDto(updatedValues);
    const dataToSubmit = getDataToSubmit(dto);
    submitIsbn(apiRoute, dataToSubmit);

    return createSubmissionPromise().then((result) => {
      if (result) {
        const resultDto = result as Partial<IsbnDTO>;
        const stepErrors = pick(resultDto, currentStep.fields || []);

        if (Object.keys(stepErrors).length) {
          scrollToTop();
          if (typeof stepErrors?.title === 'object') {
            const titleErrors = {
              ...stepErrors,
              ...stepErrors.title,
            };
            if (typeof titleErrors?.title == 'object') {
              delete titleErrors.title;
            }

            return titleErrors;
          }
          return stepErrors;
        }

        if (activeStep < steps.length - 1) {
          setActiveStep(activeStep + 1);
        }
      }
    });
  };

  return (
    <ConstrainedPageContent center={user ? false : 'xs'}>
      <Form
        initialValues={formValues || defaultIsbnFormValues(user, organisation, urlParams.isbn)}
        onSubmit={handleIsbnSubmit}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitSucceeded, dirty, ...props }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Prompt
                when={dirty}
                message={(location) => {
                  if (location.hash) return true;

                  return t('isbn_new_application_prompt');
                }}
              />
              <Grid container alignItems="flex-start" style={{ marginBottom: 20 }}>
                <Grid item style={{ width: 200 }}>
                  <PageTitle>{t('page_title_request_isbn')}</PageTitle>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Stepper
                    steps={steps.map((step) => ({ label: step.name }))}
                    alternativeLabel
                    activeStep={activeStep}
                    onSelectStep={setActiveStep}
                  />
                </Grid>
              </Grid>
              <Component
                user={user}
                changeStep={(value) => setActiveStep(activeStep + value)}
                submitSucceeded={Boolean(submitSucceeded && submitIsbnRequest?.fulfilled)}
                {...props}
                submitting={Boolean(submitIsbnRequest?.pending)}
                dirty={dirty}
              />
            </form>
          );
        }}
      />
    </ConstrainedPageContent>
  );
};

export default ISBNApplicationPage;
