import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Title } from '../../../../config/api/types';
import { updateQueryParameters } from '../../../../helpers/hateoas';

type Props = TypographyProps & {
  collection: Title['collection'];
  changeParams?: (values: Record<string, string | number>) => void;
  isDetail?: boolean;
};

const Collection = ({ collection, changeParams, isDetail, ...other }: Props) => {
  const { search } = useLocation();
  const { t } = useTranslation();

  if (!collection) {
    return null;
  }

  const text =
    !collection.number || collection.number === '0'
      ? null
      : parseInt(collection.number, 10)
      ? 'title_collection_numeric'
      : 'title_collection_alphanumeric';

  return collection.title ? (
    <Typography {...other}>
      {!text && `${t('title_collection_no_number')} `}
      <Link
        to={{
          pathname: '/titels',
          search: updateQueryParameters(search, {
            'collection.title.keyword': encodeURIComponent(collection.title),
            q: '',
          }),
          ...(isDetail && {
            state: {
              from: 'detail',
            },
          }),
        }}
        style={{ color: 'inherit' }}
        onClick={() => {
          changeParams?.({ page: 1 });
          window.scrollTo(0, 0);
        }}
      >
        {collection.title}{' '}
        <SearchIcon
          style={{
            fontSize: '1rem',
            position: 'relative',
            top: 2,
          }}
        />
      </Link>
      {!text ? null : t(text, collection)}
    </Typography>
  ) : null;
};

export default Collection;
