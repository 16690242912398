import { Box, Grid, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { TextField } from '../../../../components/Form';
import useApiRoute from '../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../helpers/hateoas';
import { selectTitle } from '../../../title/admin/title/selectors';
import { getTitleRequest } from '../../../title/detail/api';

type Props = {
  onNext: () => void;
};

const SearchTitle: FC<Props> = ({ onNext }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titles');
  const [gtin13, setGtin13] = useState('');
  const [titleFetch, getTitle] = useFetch(getTitleRequest);
  const foundTitle = useSelector(selectTitle);
  const isFulfilled = titleFetch?.fulfilled;

  const isFetchedAndFound = isFulfilled && foundTitle && gtin13 === foundTitle.gtin13;

  useEffect(() => {
    if (isFetchedAndFound) {
      onNext();
    }
  }, [onNext, isFetchedAndFound]);

  return (
    <Grid container>
      <Form
        onSubmit={(values) => {
          if (!values.gtin13) {
            return;
          }

          const updatedUrl = updateQueryParameters(url || '', {
            q: values.gtin13,
          });
          setGtin13(values.gtin13);
          getTitle(updatedUrl, values.gtin13);
        }}
        validate={(values) => {
          const gtin13 = values.gtin13;

          if (gtin13 && gtin13.length !== 13) {
            return { gtin13: t('form_validation_invalid_gtin13') };
          }

          return {};
        }}
        initialValues={{ gtin13: '' }}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit }) => {
          return (
            <Box>
              <Grid
                container
                component="form"
                onSubmit={handleSubmit}
                spacing={2}
                alignItems="flex-end"
              >
                <Grid item style={{ width: 240 }}>
                  <TextField
                    name="gtin13"
                    label={t('existing_gtin13')}
                    fullWidth
                    type="number"
                    isClearable
                    autoFocus
                  />
                </Grid>
                <Grid item>
                  <Box mb={1}>
                    <Button type="submit" disabled={titleFetch?.pending}>
                      {t('form_next')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {isFulfilled && !isFetchedAndFound ? (
                <Typography color="error" style={{ fontSize: '0.75rem' }}>
                  {t('gtin13_not_found')}
                </Typography>
              ) : null}
            </Box>
          );
        }}
      </Form>
    </Grid>
  );
};

export default SearchTitle;
