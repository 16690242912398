import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import InfoProcessing from '../components/InfoProcessing';
import OrganisationForm from '../components/OrganisationForm';
import UserForm from '../components/UserForm';
import { IsbnSteppedFormProps } from '../types';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 5,
  },
}));

const PublisherStep = ({
  user,
  submitting,
  submitErrors,
  values,
  dirtyFieldsSinceLastSubmit,
}: IsbnSteppedFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const cancelLink = usePath(user ? ROUTE_KEY.ISBN : ROUTE_KEY.LOGIN);
  const disableNext = submitting || (!user && !values.privacyConsent);
  const emailUniqueError =
    submitErrors?.login?.email === t('not_unique') && !dirtyFieldsSinceLastSubmit['login.email'];

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} md={7}>
        <UserForm user={user} EmailUniqueError={emailUniqueError} formType="isbn" />
      </Grid>

      <Grid item xs={12} md={7}>
        <Divider className={classes.divider} />
        <OrganisationForm user={user} formType="isbn" />
      </Grid>

      {!user && (
        <Grid item xs={12} md={7}>
          <Divider className={classes.divider} />
          <InfoProcessing />
        </Grid>
      )}

      <Grid container md={7} xs={12} item sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Button link={cancelLink} variant="outlined" disabled={submitting}>
            {t('form_confirm_disagree')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" disabled={disableNext}>
            {t('form_next')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PublisherStep;
