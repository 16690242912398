import { Box, MenuList, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import arrayMutators from 'final-form-arrays';
import _get from 'lodash/get';
import _set from 'lodash/set';
import moment from 'moment';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { InferRequest } from 'react-redux-fetch';
import Button from '../../../../../components/Button';
import Header from '../../../../../components/Header';
import { TitleFull } from '../../../../../config/api/types';
import useApiRoute from '../../../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';
import usePath from '../../../../../config/routes/usePath';
import { useDeprecatedAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import flattenObjectKeys from '../../../../../helpers/flattenObjectKeys';
import getIdFromUrl from '../../../../../helpers/getIdFromUrl';
import slugify from '../../../../../helpers/slugify';
import useFetch from '../../../../../helpers/useFetch';
import { ApiConfig as DetailApiConfig } from '../../../detail/api';
import { createTitleRequest, updateTitleRequest } from '../api';
import Left from '../components/Left';
import Right from '../components/Right';
import SectionNavItem from '../components/SectionNavItem';
import { TitleFormHeader } from '../components/TitleFormHeader';
import Bibliographic from '../components/sections/Bibliographic';
import Commercial from '../components/sections/Commercial';
import Contributors from '../components/sections/Contributors';
import Education from '../components/sections/Education';
import MediaFiles from '../components/sections/MediaFiles';
import MediaFilesNotPossible from '../components/sections/MediaFilesNotPossible';
import Promotion from '../components/sections/Promotion';
import Relations from '../components/sections/Relations';
import TargetAudience from '../components/sections/TargetAudience';
import Texts from '../components/sections/Texts';
import Thema from '../components/sections/Thema';
import TitleData from '../components/sections/TitleData';
import TitleFormat from '../components/sections/TitleFormat';
import TitleISBN from '../components/sections/TitleISBN';
import { mapTitleDTO } from '../dataMapper';
import { FormValues, SectionTitle, calculateSectionScore, sectionHasErrors } from '../domain';

type Props = {
  titleId?: string;
  navigate: (to: string) => void;
  previousPath?: string;
  gtin13?: string;
  isCopy?: boolean;
};

export const propertyPathMapper = {
  eBookType: 'selectedEBookType.code',
  'productForm.taxRate': 'productForm.taxRate.code',
  'productForm.productFormValidTaxRateCode': 'productForm.taxRate.code',
  'productForm.code': 'selectedProductForm',
  productForm: 'selectedProductForm',
  priceIsFreeOfCharge: 'priceDescription',
  priceIsToBeAnnounced: 'priceDescription',
  discountCode: 'discountCode.code',
  'availability.supplyDate': 'selectedSupplyDate',
  notDisplayAndHasNoDate: 'selectedSupplyDate',
  replacementOf: 'selectedReplacementOf',
  titleInOtherLanguages: 'selectedTitleInOtherLanguages',
  replacedBy: 'selectedReplacedBy',
  'availability.code': 'availability',
  'fund.code': 'fund',
  diffFirstPublishedAndPriceRegulatedStartLessThan14Days: 'priceRegulated.startDate',
  regulatedActionPriceEndDateAfterOrActionPriceStartDate: 'priceRegulatedAction.endDate',
  lastNameOrCorporateName: 'contributors',
  'keywords\\[[0-9]*\\]\\.keyword': 'keywords',
  'contributors\\[[0-9]*\\]\\.lastName': 'contributors',
  'contributors\\[[0-9]*\\]\\.firstName': 'contributors',
  'name.firstName': 'contributors',
  'name.lastName': 'contributors',
  'retailPrice.price': 'price',
};

export const actualValidationErrors = <T extends Record<string, any>>(
  validationErrors: T,
  touched?: Record<string, boolean>
): T | {} => {
  if (!touched) return {};

  // List of fields that have been touched by the user
  const touchedFields: string[] = Object.entries(touched)
    .filter(([, isTouched]) => {
      return isTouched;
    })
    .map(([field]) => field);

  // For all touched fields, check if there are validation errors
  return touchedFields.reduce((agg, field) => {
    const validationError = _get(validationErrors, field);

    if (validationError) {
      _set(agg, field, validationError);
    }
    return agg;
  }, {});
};

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    padding: 6,
    textAlign: 'center',
    maxWidth: 215,
  },
  helperText: {
    color: theme.palette.text.helpertext,
  },
}));

let submitAndGoBack = false;

const TitleForm = ({ titleId, navigate, previousPath, gtin13, isCopy }: Props) => {
  // We get a gtin13 when creating a new title OR copying a title
  // We get a titleId when updating a title OR copying a title
  // isCopy will be true when copying a title

  const { t } = useTranslation();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeSection, setActiveSection] = useState<SectionTitle>(
    window.location.hash
      ? (window.location.hash.replace('#', '') as SectionTitle)
      : !gtin13
      ? 'gtin13'
      : 'data'
  );

  const [formValues, setFormValues] = useState<FormValues | undefined>(undefined);
  const [textErrors, setTextErrors] = useState<FormValues | undefined>(undefined);
  const apiRoute = useApiRoute('titles', titleId);
  const newApiRoute = useApiRoute('titles');
  const getTitleConfig = DetailApiConfig.getTitle(apiRoute || '');
  const titleConfig =
    titleId && !isCopy
      ? updateTitleRequest(apiRoute || '')
      : createTitleRequest(newApiRoute || '', gtin13);

  const [titleFetch, fetchTitle] = useFetch<TitleFull>(getTitleConfig);
  const [saveTitleFetch, saveTitle] = useFetch<TitleFull, InferRequest<typeof titleConfig>>(
    titleConfig
  );

  const { createSubmissionPromise } = useDeprecatedAsyncValidation(
    saveTitleFetch,
    propertyPathMapper
  );

  const path = usePath(ROUTE_KEY.TITLE, {
    id: getIdFromUrl(saveTitleFetch?.value?._links.self.href) || titleId || '',
    slug: slugify(saveTitleFetch?.value?.title || '~'),
  });

  useEffect(() => {
    if (titleId) {
      fetchTitle();
    }
  }, [titleId, fetchTitle]);

  useEffect(() => {
    if (isSubmitting && saveTitleFetch && saveTitleFetch.fulfilled) {
      navigate(submitAndGoBack && previousPath ? previousPath : path);
      submitAndGoBack = false;
    }
  }, [isSubmitting, saveTitleFetch, navigate, path, previousPath]);

  const fetchValue = (titleId && titleFetch?.value) || undefined;

  const navTitle = useMemo(() => {
    if (isCopy) return t('page_title_new_title');
    if (fetchValue?.gtin13) return fetchValue.gtin13;
    if (titleId) return t('page_title_edit_title');
    else return t('page_title_new_title');
  }, [fetchValue, isCopy, t, titleId]);

  if (titleId && (!titleFetch || !titleFetch.value)) {
    return null;
  }

  return (
    <Form
      onSubmit={(formValues: FormValues) => {
        saveTitle(formValues);
        setFormValues(formValues);
        setIsSubmitting(true);
        return createSubmissionPromise();
      }}
      initialValues={
        formValues ||
        (fetchValue
          ? {
              ...mapTitleDTO(fetchValue).toFormValues(),
              ...(isCopy && {
                gtin13,
                availability: undefined,
                selectedSupplyDate: undefined,
                orderTime: undefined,
                fund: undefined,
                price: undefined,
                priceDescription: undefined,
                discountCode: undefined,
                productForm: undefined,
                priceAction: undefined,
                priceRegulated: undefined,
                priceRegulatedAction: undefined,
                hasInformationRestriction: false,
                hasOrderRestriction: false,
                replacedBy: undefined,
                replacementOf: undefined,
                selectedReplacementOf: undefined,
                selectedReplacedBy: undefined,
                legalDepotNumber: undefined,
                mediaFrontCover: undefined,
                mediaBackCover: undefined,
                mediaSample: undefined,
                isBibliographicalVerified: false,
              }),
            }
          : { gtin13, editionNumber: 1, themas: [], themaQualifiers: [] })
      }
      keepDirtyOnReinitialize={true}
      mutators={{ ...arrayMutators }}
      render={({
        handleSubmit,
        values,
        form: { change, submit },
        touched,
        submitting,
        submitErrors,
        errors: validationErrors,
      }) => {
        const allErrors =
          submitErrors && textErrors
            ? { ...submitErrors, ...textErrors }
            : submitErrors || textErrors;
        const errors = [
          ...(allErrors ? flattenObjectKeys(allErrors as {}) : []),
          ...Object.keys(actualValidationErrors(validationErrors ?? {}, touched) || {}),
        ];

        const generalError = errors.length
          ? t('form_title_submit_error')
          : saveTitleFetch?.rejected
          ? t('Unauthorized')
          : '';

        const handleTextSave = () => {
          if (fetchValue) {
            saveTitle({
              ...mapTitleDTO(fetchValue).toFormValues(),
              flapCopy: values.flapCopy,
              promotionalHeadline: values.promotionalHeadline,
              annotation: values.annotation,
            });
            createSubmissionPromise().then((result: any) => {
              setTextErrors(result ? result : undefined);
            });
          }
        };

        return (
          <>
            <Left>
              <div>
                {fetchValue?.gtin13 && !isCopy ? (
                  <TitleFormHeader
                    title={fetchValue}
                    goBackPath={previousPath || path}
                    goBackLabel={t('back_to_previous')}
                  />
                ) : (
                  <Header
                    title={navTitle}
                    goBack
                    goBackPath={previousPath || path}
                    goBackLabel={t('back_to_previous')}
                  />
                )}
                <Box pt={3} pl={2}>
                  <Typography variant="caption" className={classes.helperText}>
                    {t('title_edit_section_helper')}
                  </Typography>
                </Box>
                <MenuList>
                  {(!titleId || isCopy) && (
                    <SectionNavItem
                      section="gtin13"
                      score={calculateSectionScore('gtin13', values)}
                      title={t('title_edit_section_gtin13')}
                      hasErrors={sectionHasErrors('gtin13', errors)}
                      activeSection={activeSection}
                      onClick={setActiveSection}
                    />
                  )}
                  <SectionNavItem
                    section="data"
                    score={calculateSectionScore('data', values)}
                    title={t('title_edit_section_data')}
                    hasErrors={sectionHasErrors('data', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="format"
                    score={calculateSectionScore('format', values)}
                    title={t('title_edit_section_form')}
                    hasErrors={sectionHasErrors('format', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="bibliographic"
                    score={calculateSectionScore('bibliographic', values)}
                    title={t('title_edit_section_bib')}
                    hasErrors={sectionHasErrors('bibliographic', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="contributors"
                    score={calculateSectionScore('contributors', values)}
                    title={t('title_edit_section_contributors')}
                    hasErrors={sectionHasErrors('contributors', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="commercial"
                    score={calculateSectionScore('commercial', values)}
                    title={t('title_edit_section_commercial')}
                    hasErrors={sectionHasErrors('commercial', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="thema"
                    score={calculateSectionScore('thema', values)}
                    title={t('title_edit_section_thema')}
                    hasErrors={sectionHasErrors('thema', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="mediaFiles"
                    score={calculateSectionScore('mediaFiles', values)}
                    title={t('title_edit_section_mediaFiles')}
                    hasErrors={sectionHasErrors('mediaFiles', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="targetAudience"
                    score={calculateSectionScore('targetAudience', values)}
                    title={t('title_edit_section_targetAudience')}
                    hasErrors={sectionHasErrors('targetAudience', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="relations"
                    score={calculateSectionScore('relations', values)}
                    title={t('title_edit_section_relations')}
                    hasErrors={sectionHasErrors('relations', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="promotion"
                    score={calculateSectionScore('promotion', values)}
                    title={t('title_edit_section_promotion')}
                    hasErrors={sectionHasErrors('promotion', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="education"
                    score={calculateSectionScore('education', values)}
                    title={t('title_edit_section_education')}
                    hasErrors={sectionHasErrors('education', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                  <SectionNavItem
                    section="texts"
                    score={calculateSectionScore('texts', values)}
                    title={t('title_edit_section_texts')}
                    hasErrors={sectionHasErrors('texts', errors)}
                    activeSection={activeSection}
                    onClick={setActiveSection}
                  />
                </MenuList>
                {generalError && <div className={classes.error}>{generalError}</div>}
              </div>
              <Box>
                <Box
                  mb={2}
                  component="form"
                  onSubmit={(e: SyntheticEvent<HTMLFormElement, Event>) => {
                    submitAndGoBack = false;
                    return handleSubmit(e);
                  }}
                >
                  <Button type="submit" fullWidth disabled={submitting}>
                    {t(previousPath ? 'form_action_save_and_display' : 'form_action_save')}
                  </Button>
                </Box>
                {previousPath && (
                  <Button
                    type="submit"
                    onClick={() => {
                      submitAndGoBack = true;
                      submit();
                    }}
                    fullWidth
                    disabled={submitting}
                  >
                    {t('form_action_save_and_back')}
                  </Button>
                )}
              </Box>
            </Left>
            <Right>
              {(!titleId || isCopy) && <TitleISBN isCopy={isCopy} />}
              <TitleData
                isBibliographicalVerified={!isCopy && fetchValue?.isBibliographicalVerified}
              />
              <TitleFormat
                productFormFromServer={fetchValue?.productForm}
                selectedProductForm={values.selectedProductForm}
                selectedEBookType={values.selectedEBookType}
                onChangeFormValue={change}
                isBibliographicalVerified={!isCopy && fetchValue?.isBibliographicalVerified}
                title={fetchValue}
              />
              <Bibliographic
                publisherName={values.publisher && values.publisher.organisation.name}
                showIsBibliographicalVerified
                isBibliographicalVerified={!isCopy && fetchValue?.isBibliographicalVerified}
              />
              <Contributors
                formContributors={values.contributors || []}
                onChangeFormValue={change}
                isBibliographicalVerified={!isCopy && fetchValue?.isBibliographicalVerified}
              />
              <Commercial
                price={values.price}
                priceDescription={values.priceDescription}
                taxRate={values.productForm && values.productForm.taxRate}
                selectedProductFormTaxRateCode={
                  values.selectedProductForm &&
                  values.selectedProductForm.taxRate &&
                  values.selectedProductForm.taxRate.code
                }
                otherTaxRateAllowed={
                  values.selectedProductForm && values.selectedProductForm.otherTaxRateAllowed
                }
                priceRegulatedActivated={
                  !isCopy &&
                  (fetchValue?.priceRegulatedActivated ||
                    (fetchValue?.priceRegulated?.endDate
                      ? moment(fetchValue.priceRegulated.endDate).isBefore(moment())
                      : false))
                }
                showOrderTime
                selectedFund={values.fund}
              />
              <Thema
                selectedThemas={values.themas}
                selectedQualifiers={values.themaQualifiers}
                isBibliographicalVerified={!isCopy && fetchValue?.isBibliographicalVerified}
              />
              {titleId && !isCopy ? (
                <MediaFiles
                  mediaFrontCover={values.mediaFrontCover}
                  mediaBackCover={values.mediaBackCover}
                  mediaSample={values.mediaSample}
                  onChangeFormValue={change}
                  gtin13={values.gtin13 || ''}
                  url={fetchValue?._links?.titleTitleMediaSourceFiles?.href}
                />
              ) : (
                <MediaFilesNotPossible>{t('isbn_mediaFiles_new_title')}</MediaFilesNotPossible>
              )}
              <TargetAudience
                isBibliographicalVerified={!isCopy && fetchValue?.isBibliographicalVerified}
              />
              <Relations
                initialReplacementOf={!isCopy ? fetchValue?.replacementOf : undefined}
                initialReplacedBy={!isCopy ? fetchValue?.replacedBy : undefined}
                selectedReplacementOf={values.selectedReplacementOf}
                selectedReplacedBy={values.selectedReplacedBy}
                selectedTitleInOtherLanguages={values.selectedTitleInOtherLanguages}
                canSelectTitlesInOtherLanguages={
                  Boolean(values.languages?.length) &&
                  !values.languages?.some(({ code }) => code === 'zxx')
                }
                isBibliographicalVerified={!isCopy && fetchValue?.isBibliographicalVerified}
                languages={fetchValue?.languages}
              />
              <Promotion formAwards={values.awards || []} onChangeFormValue={change} />
              <Education />
              <Texts
                flapCopy={values.flapCopy}
                promotionalHeadline={values.promotionalHeadline}
                annotation={values.annotation}
                onChangeFormValue={change}
                onSubmit={handleTextSave}
                errors={textErrors}
              />
            </Right>
          </>
        );
      }}
    />
  );
};

export default TitleForm;
