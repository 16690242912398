import { createSelector } from 'reselect';
import {
  BulkOrdersToProcess,
  NormalizedOrders,
  NormalizedOrdersPage,
  OrderFile,
  OrderFileResult,
  OrderFiles,
  OrderLineStatuses,
  Orders,
} from '../../config/api/models/shop';
import { Maybe } from '../../config/api/types';
import { RootState } from '../../config/store/types';

const getAllOrders = (state: RootState): Maybe<NormalizedOrders> => state.data.orders;
const getOrdersPage = (state: RootState) => state.data.ordersPage;
const getOrdersToProcessPage = (state: RootState): Maybe<NormalizedOrdersPage> =>
  state.data.ordersToProcess;

export const getPagedOrderLines = (state: RootState) => state.data.allOrderLines;

type GetPagedOrders = (state: RootState) => Maybe<Orders>;

export const createGetPagedOrders = createSelector(
  getAllOrders,
  getOrdersPage,
  (allOrders, ordersPage) => {
    return (key: string): Maybe<Orders> => {
      if (!ordersPage || !ordersPage[key] || !allOrders) {
        return null;
      }

      return {
        ...ordersPage[key],
        _embedded: {
          items: ordersPage[key]._embedded.items.map((orderRef) => allOrders[orderRef]),
        },
      };
    };
  }
);

export const getOrdersToProcess: GetPagedOrders = createSelector(
  getAllOrders,
  getOrdersToProcessPage,
  (allOrders, ordersToProcess) => {
    if (!allOrders || !ordersToProcess) {
      return null;
    }
    return {
      ...ordersToProcess,
      _embedded: {
        items: ordersToProcess._embedded.items.map((orderRef) => allOrders[orderRef]),
      },
    };
  }
);

export const getOrderFiles = (state: RootState): Maybe<OrderFiles> => state.data.orderFiles;

export const getOrderFile = (state: RootState): Maybe<OrderFile> => state.data.orderFile;

export const getOrderFileResult = (state: RootState): Maybe<OrderFileResult> =>
  state.data.orderFileResult;

export const getOrderLineStatuses = (state: RootState): Maybe<OrderLineStatuses> =>
  state.data.orderLineStatuses;

export const getBulkOrdersToProcess = (state: RootState): Maybe<BulkOrdersToProcess> =>
  state.data.bulkOrdersToProcess;

export const getOrder = (state: RootState) => state.data.order;
