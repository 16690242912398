import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Grid, Popover, Theme, Tooltip, Typography, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import clx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import NumberStepField from '../../../../components/Form/NumberStepField';
import { ElasticShoppingCartLine } from '../../../../config/api/models/shop';
import { TitleFull } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import { useDisclosure } from '../../../../helpers/useDisclosure';
import security from '../../../security';
import { getAddItemToCartRequest } from '../api';
import { elasticGetCantBeOrderedMessage, shoppingCartContainsTitle } from '../domain';
import { getShoppingCart } from '../selectors';
import { AddRefModal } from './AddRefModal';
import OrderReplacedByModal from './OrderReplacedByModal';

const useStyles = makeStyles((theme: Theme) => ({
  addToCart: {
    width: '13rem',
    float: 'right',
  },
}));

type Props = {
  orderLine: ElasticShoppingCartLine;
  className?: string;
  condensed?: boolean;
  initialValue?: number;
  orderLineRef?: string;
  onAddToCart?: () => void;
};

const ButtonWrapper = styled('div')({
  position: 'relative',
  height: '100%',
  '&:hover > button:first-child': {
    top: 36,
    opacity: 1,
    zIndex: 999999,
    boxShadow: '0 2px 8px rgba(0,0,0,0.4)',
  },
});
const RefButton = styled(Button)({
  position: 'absolute',
  left: 0,
  top: 0,
  opacity: 0,
  borderTop: '4px solid white',
  transition: 'all 0.2s ease',
});

const AddToCart = ({
  orderLine,
  className,
  condensed,
  initialValue,
  orderLineRef,
  onAddToCart,
}: Props) => {
  const refBtn = useRef(null);
  const [quantity, setQuantity] = useState(initialValue || 1);
  const [reference, setReference] = useState('');
  const { t } = useTranslation();
  const userRoles = useSelector(security.selectors.getUserRole);
  const url = useApiRoute('shoppingCartLines');
  const [showReplacementModal, setShowReplacementModal] = useState<boolean>(false);
  const {
    isOpen: isRefModalOpen,
    onClose: onCloseRefModal,
    onOpen: onOpenRefModal,
  } = useDisclosure();

  // const [addToCartFetch, addToCart] = useFetch(addItemToCartRequest(url || ''));
  const [addToCartFetch, addToCart] = useFetch(getAddItemToCartRequest);
  const shoppingCart = useSelector(getShoppingCart);
  const classes = useStyles();
  const isFulfilled = addToCartFetch?.fulfilled;
  const { foundOrderLine: titleInCart, foundOrderLineWithoutRef: titleInCartWithoutRef } =
    shoppingCartContainsTitle(shoppingCart, orderLine.gtin13);

  const errorMessages = addToCartFetch?.rejected && addToCartFetch?.reason?.cause;
  const referenceErrorMessage = (
    (errorMessages && errorMessages.find((m: any) => m.property_path === 'reference')) ||
    {}
  ).message;
  const quantityErrorMessage = (
    (errorMessages && errorMessages.find((m: any) => m.property_path === 'quantity')) ||
    {}
  ).message;

  useEffect(() => {
    if (isFulfilled) {
      onCloseRefModal();
      setReference('');
      onAddToCart?.();
    }
  }, [isFulfilled, onAddToCart, onCloseRefModal]);

  const elasticHandleAddToCart = (product: ElasticShoppingCartLine) => {
    const productId = getIdFromUrl(orderLine._links.title.href);
    const qty = reference ? quantity : (titleInCart?.quantity || 0) + quantity;
    addToCart(
      url || '',
      {
        reference,
        quantity: qty,
        titleId: productId,
        orderLineReferenceId: orderLineRef,
      },
      {
        qty,
        title: product.titleTitle,
        isbn: product.gtin13,
        productForm: product.productFormShortLabel ?? '',
        itemExistsInCart: reference ? false : Boolean(titleInCart),
      }
    );
  };

  const handleAddToCart = (product: TitleFull) => {
    const productId = getIdFromUrl(product._links.self.href);
    const qty = reference ? quantity : (titleInCart?.quantity || 0) + quantity;
    addToCart(
      url || '',
      {
        reference,
        quantity: qty,
        titleId: productId,
        orderLineReferenceId: orderLineRef,
      },
      {
        qty,
        title: product.title,
        isbn: product.gtin13,
        productForm: product.productForm.shortLabel,
        itemExistsInCart: reference ? false : Boolean(titleInCart),
      }
    );
  };

  const hasOrderableReplacement = () => {
    const isOrderable = Boolean(
      (orderLine.replacedByOrderableByBookstore && userRoles.includes('ROLE_BOOKSTORE')) ||
        (orderLine.replacedByOrderableByLibrary && userRoles.includes('ROLE_LIBRARY'))
    );
    return isOrderable;
  };

  const orderableReplacementLink = () => {
    if (orderLine.replacedByTitleId) {
      return `/titels/detail/${orderLine.replacedByTitleId}`;
    }
    return '';
  };

  const cannotOrderMessage = elasticGetCantBeOrderedMessage(orderLine, userRoles);

  const emptyTranslatedCannotOrderMessage =
    t(cannotOrderMessage + (condensed ? '_condensed' : ''), { context: userRoles[0] }).replace(
      ' ',
      ''
    ) === '';

  if (cannotOrderMessage) {
    const replacementLink = orderableReplacementLink();

    return (
      <>
        {!emptyTranslatedCannotOrderMessage && (
          <Typography align="right" style={{ marginTop: 12 }}>
            {t(cannotOrderMessage + (condensed ? '_condensed' : ''), { context: userRoles[0] })}
          </Typography>
        )}
        {Boolean(hasOrderableReplacement() && replacementLink) && (
          <Button variant="text" link={replacementLink} style={{ display: 'inline' }}>
            {t('title_orderable_replacement')}
          </Button>
        )}
      </>
    );
  }

  if (!url) {
    return null;
  }

  const cartBtn = (disabled: boolean = false) => (
    <Button
      size="small"
      style={{ height: '100%' }}
      onClick={() =>
        hasOrderableReplacement()
          ? setShowReplacementModal(true)
          : elasticHandleAddToCart(orderLine)
      }
      disabled={addToCartFetch?.pending || disabled}
      color={titleInCartWithoutRef ? 'inherit' : 'primary'}
      variant={titleInCartWithoutRef ? 'outlined' : 'contained'}
    >
      <ShoppingCartIcon />
    </Button>
  );

  const btn = (
    <ButtonWrapper ref={refBtn}>
      <RefButton size="small" onClick={onOpenRefModal}>
        + Ref.
      </RefButton>
      {cartBtn(isRefModalOpen)}
    </ButtonWrapper>
  );

  return (
    <Grid className={clx(classes.addToCart, className)} container spacing={2} wrap="nowrap">
      <Grid item>
        <NumberStepField
          value={quantity}
          onChange={setQuantity}
          disabled={isRefModalOpen}
          error={!isRefModalOpen && Boolean(quantityErrorMessage)}
          helperText={isRefModalOpen ? '' : quantityErrorMessage}
        />
      </Grid>
      <Grid item>
        {titleInCartWithoutRef ? (
          <Tooltip title={t('cart_title_exists')} placement="top-end">
            {btn}
          </Tooltip>
        ) : (
          btn
        )}
        {showReplacementModal && (
          <OrderReplacedByModal
            titleUrl={getLink(orderLine, 'replacedByTitle') || ''}
            replacedByGtin13={orderLine.replacedBy}
            onAccept={(replacedBy) => {
              setShowReplacementModal(false);
              handleAddToCart(replacedBy);
            }}
            onDecline={() => {
              setShowReplacementModal(false);
              elasticHandleAddToCart(orderLine);
            }}
            onClose={() => setShowReplacementModal(false)}
          />
        )}
        {isRefModalOpen && (
          <Popover
            open={true}
            anchorEl={refBtn?.current}
            onClose={onCloseRefModal}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <AddRefModal
              onClose={onCloseRefModal}
              quantity={quantity}
              setQuantity={setQuantity}
              reference={reference}
              setReference={setReference}
              refError={referenceErrorMessage}
              qtyError={quantityErrorMessage}
            >
              {cartBtn()}
            </AddRefModal>
          </Popover>
        )}
      </Grid>
    </Grid>
  );
};

export default AddToCart;
