import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import { Maybe, PrefixApplications } from '../../../../config/api/types';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import { calculatePrefixSizeBasedOnPrefix } from '../../domain';

type Props = {
  prefixApplications: Maybe<PrefixApplications>;
  dispatchFn: (url: string) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  doubleLineCell: {
    margin: '3px 0px',
  },
}));

const PrefixApplicationTable = ({ prefixApplications, dispatchFn }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const path = usePath(ROUTE_KEY.ADMIN_PREFIX_APPLICATION);
  const headers = [
    { id: 'date/distributor', label: t('table_headers_date_publisher') },
    { id: 'contactPerson', label: t('table_headers_contact_person') },
    { id: 'prefix', label: t('table_headers_prefix') },
  ];

  if (!prefixApplications) {
    return null;
  } else if (prefixApplications._embedded.items.length < 1) {
    return <>{t('prefix_application_empty_list')}</>;
  }

  const doubleLineCell = (firstLine: string, secondLine: string) => (
    <Grid container direction="column" className={classes.doubleLineCell}>
      <Grid item>{firstLine}</Grid>
      <Grid item>{secondLine}</Grid>
    </Grid>
  );

  const data: DataType = {
    resource: 'prefixApplications',
    rows: prefixApplications._embedded.items.map((application) => ({
      id: getLink(application, 'self') || '',
      link: path.replace(':id', getIdFromUrl(getLink(application, 'self') || '')),
      variant: application.state === 'on_hold' ? 'red' : undefined,
      cells: [
        {
          type: CellType.Text,
          data: doubleLineCell(
            moment(application.createdAt).format('DD-MM-YYYY HH:mm'),
            application.organisation.name
          ),
        },
        {
          type: CellType.Text,
          data: doubleLineCell(
            application.creator.firstName
              ? `${application.creator.firstName} ${application.creator.lastName}`
              : application.creator.lastName,
            `${application.creator.email}`
          ),
        },
        {
          type: CellType.Text,
          data: t('isbn_requested_numbers', {
            count:
              calculatePrefixSizeBasedOnPrefix(application.prefix) ||
              parseInt(application.prefixSize),
          }),
        },
      ],
    })),
  };

  return (
    <>
      <Table
        headers={headers}
        data={data}
        page={prefixApplications.page}
        pages={prefixApplications.pages}
        total={prefixApplications.total}
        limit={prefixApplications.limit}
        pagedResource={prefixApplications}
        dispatchFn={dispatchFn}
        hidePagination
      />
    </>
  );
};

export default PrefixApplicationTable;
